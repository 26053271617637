import React, { useState, useEffect, useRef } from "react";
import * as echarts from "echarts";
import styles from "./WebsiteNotFound.module.scss";
import {getRquest} from "../../api"
import Chart from "react-apexcharts";
import Markdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import { Tooltip } from "react-tooltip";
import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import {
  StoriesPageHeader,
  StoryBody,
  StoryDownloadBtn,
} from "../../components/elements/stories_page/StoriesPage";
import { BlogTags } from "../../components/elements/Elements";
import Loader from "../../components/loader/Loader";
import MetaTags from "../../components/meta_tags/MetaTags";
import { Helmet } from "react-helmet-async";

const circle_colors = [
  "#005F73",
  "#F1D900",
  "#5B8C5A",
  "#AE2012",
  "#D87B00",
  "#7CC0E6",
  "#B270AD",
  "#A0C66F",
  "#9BA4A3",
  "#C3979F",
  "#A48631",
  "#89AAF4",
  "#1F5725",
  "#89746B",
];

// Sample data transformation function
const transformData = (data) => {
  const ministries = {};
  const colors = {};
  let counter = 0;

  data.forEach((item, index) => {
    const ministry = item.attributes.order_issued_bies.data[0].attributes.name;
    const legislation =
      item.attributes.legislation_blocked_unders.data[0].attributes.name;
    if (!ministries[ministry]) {
      const color = circle_colors[counter];
      counter++;
      ministries[ministry] = { name: ministry, children: [], color: color };
      colors[ministry] = color;
    }

    const legislationIndex = ministries[ministry].children.findIndex(
      (child) => child.name === legislation
    );
    if (legislationIndex === -1) {
      ministries[ministry].children.push({
        name: legislation,
        value: 1, // Initialize with 1 instance
        color: ministries[ministry].color,
      });
    } else {
      ministries[ministry].children[legislationIndex].value += 1; // Increment the instance count
    }
  });
  return {
    name: "root",
    children: Object.values(ministries),
    colors: colors,
  };
};

// CirclePackingChart Component
const CirclePackingChart = ({ apiData }) => {
  const transformedData = transformData(apiData);
  const { colors, ...data } = transformedData;

  return (
    <div>
      <div style={{ height: "500px", margin: "auto" }}>
        <ResponsiveCirclePacking
          data={data}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          id="name"
          value="value"
          colors={({ data }) => data.color}
          childColor={{ from: "color", modifiers: [["brighter", 0.4]] }}
          padding={4}
          enableLabels={false}
          labelsFilter={function (d) {
            return d.node.depth === 2;
          }}
          labelsSkipRadius={10}
          labelTextColor={{ from: "color", modifiers: [["darker", 2.4]] }}
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.3]] }}
          inheritColorFromParent={true}
          // Filter out the root node and very small circles
          // leavesOnly={true}
          nodeComponent={(props) => {
            if (props.node.depth === 0) {
              // Hide the root node
              return null;
            }
            if (props.node.value < 2) {
              // Filter out small circles based on a value threshold
              return null;
            }
            return <circle {...props} />;
          }}
        />
      </div>
      <div
        className={styles.circle_legend}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
          flexWrap: "wrap",
        }}
      >
        {Object.entries(colors).map(([ministry, color]) => (
          <div
            key={ministry}
            style={{ display: "flex", alignItems: "center", margin: "0 10px" }}
          >
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: color,
                marginRight: 5,
              }}
            ></div>
            <span>{ministry}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

function ToReadOrNot() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}Website-Blockings?populate=*&pagination[pageSize]=500`
        ); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return data; // Return the fetched data once available
}

const BlockBanChart = ({ data,ChartLink,setClickedData }) => {
  const chartValue=ChartLink.map(item=>item.attributes)
  const chartRef = useRef(null);

  useEffect(() => {
    const prepareChartData = (chartValue) => {
      const counts = {};
  
      chartValue.forEach((entry) => {
        try {
          const year = entry.year;
          const numbers = entry.Numbers;
          const links = entry.links;
  
          if (!counts[year]) {
            counts[year] = { total: 0, links: [] };
          }
  
          // Accumulate total numbers per year
          counts[year].total += numbers;
  
          // Store the links for each year
          if (links) {
            counts[year].links.push(links);
          }
        } catch (error) {
          // console.error("Error processing entry:", entry, error);
          
        }
      });
  
      const years = Object.keys(counts).sort((a, b) => a - b);
      const categories = years;
      const series = [
        {
          name: "Total number of websites/urls/social media accounts takedowns",
          type: "bar",
          data: categories.map((year) => counts[year].total || 0),
        },
      ];
  
      return { series, categories, counts };
    };
  
    const result = prepareChartData(chartValue); // Using chartValue here

    const chart = echarts.init(chartRef.current);
  
    const options = {
      title: {
        left: "center",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        enterable: true, // This allows mouse to enter the tooltip
        confine: true,   // Ensures tooltip stays within chart bounds
        hideDelay: 300,
        formatter: function (params) {
          let tooltipContent = `<strong>${params[0].name}</strong><br/>`; // Year
          
          params.forEach((item) => {
            tooltipContent += `${item.seriesName}: ${item.value}<br/>`;
          });
  
          // Add the links to the tooltip
          const year = params[0].name;
          const links = result.counts[year]?.links;
          if (links && links.length > 0) {
            links.forEach((link) => {
              let linkContent = link;
              if (typeof linkContent === "string") {
                // Handle Markdown links
                const match = linkContent.match(/\[(.*?)\]\((.*?)\)/);
                if (match) {
                  linkContent = `<a href="${match[2]}" target="_blank" style="text-decoration: none; color: inherit;">${match[1]}</a>`;
                }
              }
              tooltipContent += `${linkContent}<br/>`;
            });
          }
  
          return tooltipContent;
        },
      },
      legend: {
        top: "bottom",
      },
      xAxis: {
        type: "category",
        data: result.categories,
        name: "Year",
      },
      yAxis: {
        type: "value",
        name: "Number of Blocks",
      },
      series: result.series,
      color: ["#252525", "#F29454", "#78A57099"], // You can adjust the colors based on your design
    };
  
    chart.setOption(options);
    chart.on("click", function (event){
        let year = event.name
        setClickedData({
          year, // Add year as part of the clicked data
          data: result.counts[year], // Add the corresponding data for that year
        });

    }) 
  
    return () => {
      chart.dispose();
    };
  }, []);
  


  return <div ref={chartRef} style={{ width: "100%", height: "400px" }}></div>;
};

const ReasonsPieChart = ({ data }) => {
  // Step 4: Parse data and prepare chart series
  const reasonsCount = {};

  // Count reasons from data
  data.forEach((item) => {
    const reasonName = item.attributes.reasons.data[0].attributes.name;
    if (reasonsCount[reasonName]) {
      reasonsCount[reasonName]++;
    } else {
      reasonsCount[reasonName] = 1;
    }
  });

  // Prepare data for ApexCharts
  const chartData = {
    series: Object.values(reasonsCount),
    options: {
      chart: {
        type: "pie",
        title: "Reasons",
      },
      tooltip: {
        styles: {
          color: "black",
          backgroundColor: "white",
        },
        theme: false,
      },
      labels: Object.keys(reasonsCount),
      legend: {
        show: true,
        position: "bottom",
      },
      colors: [
        "#005F73",
        "#F1D900",
        "#5B8C5A",
        "#422040",
        "#AE2012",
        "#D87B00",
        "#7CC0E6",
        "#B270AD",
        "#A0C66F",
        "#9BA4A3",
        "#C3979F",
        "#A48631",
        "#89AAF4",
        "#1F5725",
        "#89746B",
      ],
      // colors: [
      //   "#2085D6", "#FFD450", "#BD0101", "#78A570", "#FFD450", "#F29454",
      //   // Repeat or add more colors as needed
      // ],
    },
  };

  return (
    <div className="reasons-pie-chart">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width="100%"
      />
    </div>
  );
};

const ShowClickedDataComponent = ({data}) => {return <div className={styles.clicked_data}>
  <p><b>Year - </b>{data.year}</p>
  <p><b>Total number of websites/urls/social media accounts takedowns - </b>{data.data.total}</p>
  <p><b>Links - </b></p>
  <ul>
  {data.data.links.map((link, index) => (
                    <li key={index}>
                      <Markdown rehypePlugins={[rehypeRaw]}>{link}</Markdown>
                    </li>
                  ))}
  </ul>
  
</div>}

function WebsiteNotFound({ setReport }) {
  const [ChartLinkData,setChartLinkData]=useState("")
  const [clickedData, setClickedData] = useState(null)

  useEffect(()=>{
    const fetchChartData = async () => {
      try {
        const response = await getRquest(
          "website-blocking-totals"
        );
        setChartLinkData(response.data);
      } catch (error) {
       
      }
    };
    fetchChartData();
  },[])


  const data = ToReadOrNot(); // Fetch data using ToReadOrNot function

  if (!data) {
    return (
      <div>
        <Loader />
      </div>
    ); // Handle loading state if data is not yet fetched
  }
  const breadcrumb = {
    page1: { name: "HOME", url: "/" },
    page2: { name: "STORIES", url: "/stories" },
  };
  return (
    <div className={styles.container}>
      <Helmet>
        {MetaTags(
          "404: Website Not Found",
          "Ever encountered Error 404? Or suddenly cut off from your favourite websites? Perhaps your once-active TikTok account lost all its followers?",
          "./images/featured_stories/3.png",
          window.location.href
        )}
      </Helmet>
      <StoriesPageHeader
        setReport={setReport}
        img={"./images/featured_stories/3.png"}
        title={"404: Website Not Found"}
        sub_heading={
          "Ever encountered Error 404? Or suddenly cut off from your favourite websites? Perhaps your once-active X account lost all its followers?"
        }
        tag={"Website Block"}
        tag_bg={"cyan"}
        breadcrumb={breadcrumb}
      />
      <div className={styles.spacing_50}></div>
      <div className="slim_container">
        <div className="charts_container charts_100">
          <div className="charts_container_wrapper">
            <BlockBanChart data={data.data} ChartLink={ChartLinkData} setClickedData={setClickedData}/>
            
            {clickedData && <><div className={styles.spacing_50}></div><ShowClickedDataComponent data={clickedData}/></>} 
          </div> 
          {/* Pass fetched data to BlockBanChart */}
        </div>
      </div>
      {/* <div className={styles.spacing_20}></div> */}
      <StoryBody
        desc={
          "In India, website and app blocking is primarily governed by the Information Technology Act, 2000 (IT Act), which serves as the key legislation regulating digital content. The two main mechanisms for blocking content under this law are (1) Section 69A of the IT Act, which empowers the Central Government to block access to online content, including websites, applications, and social media platforms, in the interest of national security, public order, or sovereignty, and (2) the content takedown regime under Section 79, which allows intermediaries to remove unlawful content upon receiving government or court orders. Various ministries and regulatory bodies are authorized to issue such takedown orders under the above mentioned sections of the IT Act. Beyond the IT Act, other legal frameworks such as the Copyright Act, 1957, and the Trade Marks Act, 1999, also empower authorities to take down content infringing intellectual property rights. These laws are frequently invoked in cases related to online piracy and brand misuse."
        }
      />
            <div className={styles.spacing_20}></div>
      <StoryBody
        desc={
          "Despite the broad legal scope of website and app blocking, our investigation found that only about 30% of blocked entities could be identified, leaving the majority of censorship actions untraceable."
        }
      />
      <div className={styles.spacing_70}></div>

      <div className="slim_container">
        <ReasonsPieChart data={data.data} />
      </div>

      <div className={styles.spacing_70}></div>
      <StoryBody
        desc={
          "Our analysis indicates that the five most common reasons for website and app bans in India include piracy, cyber crimes, dissemination of obscene content, involvement in illegal betting activities, and threats to national security. This lack of transparency raises serious concerns about accountability of enforcement and regulatory bodies, as the blocking process is not publicly disclosed, and affected parties often have no clear avenue for redressal. "
        }
      />

      <div className="charts_container">
        <div className={styles.circle_parking_chart} style={{ height: 600 }}>
          <CirclePackingChart apiData={data.data} />
        </div>
      </div>
      
      <div className={styles.spacing_20}></div>
      <StoryBody
        desc={
          "This lack of transparency raises serious concerns about accountability, as the blocking process is not publicly disclosed, and affected parties often have no clear avenue for appeal."
        }
      />

      <div className="slim_container" style={{ display: "none" }}>
        {/* remove display none style from hare you can see the band website list */}
        <h2 className={styles.lets_explore}>Let’s explore this land of bans</h2>
      </div>
      {/* remove display none style from hare you can see the band website list */}

      <div className="slim_container" style={{ display: "none" }}>
        <div className={styles.post_kind_word_cloud}>
          <Tooltip
            className={styles.tooltip_class}
            id="my-tooltip-proton"
            clickable
          />
          <Tooltip
            className={styles.tooltip_class}
            id="my-tooltip-airalo"
            clickable
          />
          <Tooltip
            className={styles.tooltip_class}
            id="my-tooltip-holafly"
            clickable
          />
          <Tooltip
            className={styles.tooltip_class}
            id="my-tooltip-freedom"
            clickable
          />
          <Tooltip
            className={styles.tooltip_class}
            id="my-tooltip-briar"
            clickable
          />
          <Tooltip
            className={styles.tooltip_class}
            id="my-tooltip-anil"
            clickable
          />

          <Tooltip
            className={styles.tooltip_class}
            id="my-tooltip-Zlibrary"
            clickable
          />
          <Tooltip
            className={styles.tooltip_class}
            id="my-tooltip-TikTok"
            clickable
          />
          <Tooltip
            className={styles.tooltip_class}
            id="my-tooltip-PubG"
            clickable
          />
          <Tooltip
            className={styles.tooltip_class}
            id="my-tooltip-rana"
            clickable
          />
          <Tooltip
            className={styles.tooltip_class}
            id="my-tooltip-farmers"
            clickable
          />
          <Tooltip
            className={styles.tooltip_class}
            id="my-tooltip-satta"
            clickable
          />
          {/* <p className={styles.red_p}>
            Proton Mail{" "}
              <img
                src="./images/i_btn.png"
                alt="tooltip"
                data-tooltip-id="my-tooltip-proton"
                data-tooltip-content={`It was banned after police were unable to trace the perpetrators behind fake bomb threats sent to schools using Proton Mail.`}
              />
            </p> */}
          <p className={styles.red_p}>
            Airalo{" "}
            <img
              src="./images/i_btn.png"
              alt="tooltip"
              data-tooltip-id="my-tooltip-airalo"
              data-tooltip-content="A software engineer was fired after tweeting about tech industry recession and expressing career concerns."
            />
          </p>
          <p>moviesdrive.rent</p>
          <p>medeberiyas.com</p>
          <p>ofilmyzilla.it</p>
          <p className={styles.red_p}>
            Let India Breathe{" "}
            <img
              src="./images/i_btn.png"
              alt="tooltip"
              data-tooltip-id="my-tooltip-holafly"
              data-tooltip-content={`The website of Let India Breathe and two others was blocked by Centre-run agency: National Internet Exchange of India after they criticised draft of new environmental clearance laws.`}
            />
          </p>
          <p>ofilmywap.hk.com</p>
          <p>ww4.ashigana.com</p>
          <p>hdhub4u.boutique</p>
          <p className={styles.red_p}>
            Freedom House Tweets
            <img
              src="./images/i_btn.png"
              alt="tooltip"
              data-tooltip-id="my-tooltip-freedom"
              data-tooltip-content={`The tweets talked about the state of internet freedom and noted its sharp decline in India.`}
            />
          </p>
          <p>dudefilms.beauty</p>
          <p>teşekkur.com</p>
          <p className={styles.red_p}>
            Briar{" "}
            <img
              src="./images/i_btn.png"
              alt="tooltip"
              data-tooltip-id="my-tooltip-briar"
              data-tooltip-content={`14 Apps were banned as they are supposedly used by terrorists and they don’t have representative here.`}
            />
          </p>
          <p>freemovieswatch.tv </p>
          <p>123animes.mobi</p>
          <p className={styles.red_p}>
            Anil Kapoor Style{" "}
            <img
              src="./images/i_btn.png"
              alt="tooltip"
              data-tooltip-id="my-tooltip-anil"
              data-tooltip-content={
                "Delhi High Court on restrained unauthorised use of actor Anil Kapoor's name, image, voice for commercial purposes. "
              }
            />
          </p>
          <p>afilmywap.it </p>
          <p> ww4.1tamildhool.cam</p>
          <p>hdmoviehub.lat</p>
          <p>losmovies.ru</p>
          <p className={styles.red_p}>
            Z library{" "}
            <img
              src="./images/i_btn.png"
              alt="tooltip"
              data-tooltip-id="my-tooltip-Zlibrary"
              data-tooltip-content={
                "It was being sued for copyright infringement by academic publishers. However, Open Access is dire to knowledge distribution."
              }
            />
          </p>
          <p>okhatrimaza.com.de</p>
          <p>hdhub4one.mom</p>
          <p> jerrynet.click</p>
          <p>anupamaai.net</p>
          <p className={styles.red_p}>
            TikTok{" "}
            <img
              src="./images/i_btn.png"
              alt="tooltip"
              data-tooltip-id="my-tooltip-TikTok"
              data-tooltip-content={`Apps used by millions in India for everything from selfies to gaming banned as they promoted activities “prejudicial to sovereignty and integrity of India, defence of India, security of state and public order”.`}
            />
          </p>
          <p>ww8.5movierulz.vc  </p>
          <p className={styles.red_p}>
            PubG{" "}
            <img
              src="./images/i_btn.png"
              alt="tooltip"
              data-tooltip-id="my-tooltip-PubG"
              data-tooltip-content={`Apps used by millions in India for everything from selfies to gaming banned as they promoted activities “prejudicial to sovereignty and integrity of India, defence of India, security of state and public order”.`}
            />
          </p>
          <p>kathaankahee.pk </p>
          <p> ww1.1todaypk.info</p>
          <p>kinogo.biz</p>
          <p>gohd1.rent</p>
          <p>hdking.ch </p>
          <p className={styles.red_p}>
            Rana Ayyub Tweets{" "}
            <img
              src="./images/i_btn.png"
              alt="tooltip"
              data-tooltip-id="my-tooltip-rana"
              data-tooltip-content={
                "She has been shadow banned multiple times by twitter at the behest of government. Her tweets critical of Gyanvapi mosque and shooting spree by a Railway Protection Force constable have been taken down. CJ Werleman’s Twitter account, who writes on Islamophobia, saw a similar fate."
              }
            />
          </p>
          <p>terimeridooriyan.org</p>
          <p>filmyworlds.ink</p>
          <p>tvbiwi.cc </p>
          <p>hdmovies4u.green </p>
          <p className={styles.red_p}>
            Farmers Protest{" "}
            <img
              src="./images/i_btn.png"
              alt="tooltip"
              data-tooltip-id="my-tooltip-farmers"
              data-tooltip-content={`Meity finalised its emergency blocking orders against 177 social media accounts and links related to the farmers’ protests in Feb 24 to maintain “public order." Accounts like Tractor to Twitter, a digital news portal known for its support of farmer's rights or Gaon Savera, journalist Mandeep Punia and tribal activist Hansraj Meena have been abruptly suspended.`}
            />
          </p>
          <p>full4movies.club</p>
          <p>coolmoviez.cloud</p>
          <p>ww5.ibomma.day </p>
          <p className={styles.red_p}>
            Satta{" "}
            <img
              src="./images/i_btn.png"
              alt="tooltip"
              data-tooltip-id="my-tooltip-satta"
              data-tooltip-content={
                "Multiple websites and apps were stealing the data of broadcasting platforms and streaming cricket matches live to enable betting."
              }
            />
          </p>
          <p>www.sasuralsimarkaa.net</p>
          <p>{`movie.latama-aws-]r12.pw `} </p>
          <p>serialmaza.net</p>
          <p></p>
        </div>
      </div>
      <div className={"slim_container"+" "+styles.download_btn}>
        <StoryDownloadBtn setReport={setReport} ban={"Website"} />
      </div>
    </div>
  );
}

export default WebsiteNotFound;
